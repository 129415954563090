export default [
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/dashboard/home/Home.vue'),
    meta: {
		resource: 'Auth',
    },
  },
  {
    path: '/boxea',
    name: 'boxea',
    component: () => import('@/views/dashboard/boxea/tb.vue'),
    meta: {
		resource: 'Auth',
    },
  },
  {
    path: '/izibox',
    name: 'izibox',
    component: () => import('@/views/dashboard/izibox/tb.vue'),
    meta: {
		resource: 'Auth',
    },
  },
  {
    path: '/domicializ',
    name: 'domicializ',
    component: () => import('@/views/dashboard/domicializ/tb.vue'),
    meta: {
		resource: 'Auth',
    },
  },
  {
    path: '/resa/:id',
    name: 'resa',
    component: () => import('@/views/resa/resa.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/resa2/:id',
    name: 'resa2',
    component: () => import('@/views/resa/resa2.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
]
